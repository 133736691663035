import { useTranslation } from "next-i18next";
import { HttpError } from "./HttpError";

export const ApplicationError = () => {
  const { t } = useTranslation();
  return (
    <HttpError
      code="application"
      title={t("Sorry man, we seem to be having an issue")}
      description={t(
        "This page isn’t working right. There’s a developer crying somewhere right now, so please use this button."
      )}
    />
  );
};
