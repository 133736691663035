import { useTranslation } from "next-i18next";
import { HttpError } from "./HttpError";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <HttpError
      code={404}
      title={t("Hmm, this page doesn’t exist")}
      description={t("That link didn’t work. Let’s help you get back on the right path.")}
    />
  );
};
