import { useTranslation } from "next-i18next";
import { Link as UikitLink, TwitterIcon, Button } from "@looksrare/uikit";
import { HttpError } from "./HttpError";

export const ServiceUnavailable = () => {
  const { t } = useTranslation();
  return (
    <HttpError
      code={503}
      title={t("We’ll be right back")}
      description={t(
        "YOLO Games is temporarily unavailable due to maintenance. All funds are safu and will be right where you left them"
      )}
      button={
        <UikitLink isExternal href="https://twitter.com/YOLO_Blast">
          <Button>
            {t("Get Live Updates")}
            <TwitterIcon ml={1} />
          </Button>
        </UikitLink>
      }
    />
  );
};
