import { useTranslation } from "next-i18next";
import { HttpError } from "./HttpError";

export const Forbidden = () => {
  const { t } = useTranslation();
  return (
    <HttpError
      code={403}
      title={t("Uhhh...are you lost?")}
      description={t("You don’t have permission to access this content. Let’s get you back to the games.")}
    />
  );
};
