import { ReactNode } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Flex, HStack, VStack } from "@chakra-ui/react";
import { Button, Text } from "@looksrare/uikit";
import { useIsMounted } from "@looksrare/utils";
import { Page, YoloGamesPageProps } from "../Page";

export interface HttpErrorProps extends YoloGamesPageProps {
  title: string;
  description: ReactNode;
  code: 404 | 500 | 403 | 503 | "application";
  button?: ReactNode;
}

const getImage = (code: HttpErrorProps["code"]) => {
  switch (code) {
    case 404:
    case 500:
    case 403:
      return {
        src: `url('/images/textures/${code}.png')`,
        width: "888px",
        height: "339px",
      };
    case 503:
      return {
        src: `url('/images/textures/yolo-wheel-bg.png')`,
        width: "858px",
        height: "501px",
        fit: "cover",
      };
    case "application":
      return {
        src: `url('/images/textures/yolo-logo.png')`,
        width: "888px",
        height: "330px",
      };
  }
};

/**
 * 503 - Service Unavailable
 */
export const HttpError = ({ title, description, code, button }: HttpErrorProps) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { back } = useRouter();
  const image = getImage(code);

  return (
    // No page title needed here
    <Page title="">
      <Flex bg="ui-bg" height="90dvh" position="relative" width="100%" justifyContent="center" overflowX="hidden">
        <VStack
          overflowX="hidden"
          px={8}
          textAlign="center"
          height="100%"
          mt={0}
          justifyContent={"center"}
          alignItems="center"
          maxWidth="680px"
          spacing={8}
          _before={{
            content: '""',
            position: "absolute",
            right: 0,
            bottom: 0,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: image.fit ?? "fit",
            width: image.width,
            height: image.height,
            // Avoid flashing the wrong background image on initial load
            bgGradient: isMounted ? image.src : undefined,
          }}
        >
          <Text textStyle="display-02" color="text-01" bold>
            {title}
          </Text>
          <Text color="text-02">{description}</Text>
          <HStack justifyContent="center">
            {button ?? (
              <Button onClick={back} mr={2}>
                {t("Turn Around")}
              </Button>
            )}
          </HStack>
        </VStack>
      </Flex>
    </Page>
  );
};
